import React, {useCallback, useEffect, useRef, useState} from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import {Box} from "@mui/material";
import {useAppContext} from "../contexts/appContext";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {useDbContext} from "../contexts/dbContext";

export default function RotePlayer(props) {
    const {matchreq} = props;
    const {session, version, company, product, playConfirm, setPlayConfirm, id, inMatch, setIsInMatch, matchID, setMatchID, compareMatch} = useAppContext();
    const {activeDeck, cardbacks ,myDecks, units, actions, resources, elements, keywords, identities} = useDbContext();
    const [devicePixelRatio, setDevicePixelRatio] = useState(
        window.devicePixelRatio
    );
    const canvasRef = useRef(null);
    const { unityProvider, isLoaded, loadingProgression, sendMessage, unload, addEventListener, removeEventListener } =
        useUnityContext({
            loaderUrl: "Build2/Build.loader.js",
            dataUrl: "Build2/Build.data.gz",
            frameworkUrl: "Build2/Build.framework.js.gz",
            codeUrl: "Build2/Build.wasm.gz",
            companyName: company,
            productName: product,
            productVersion: version,
        });
    const [DataSent, setDataSent] = useState(false);
    const loadingPercentage = Math.round(loadingProgression * 100);

    const buttonstyle = "flex flex-row w-24 h-14 bg-[#51b78c] border border-[#51b78c] hover:opacity-75 items-center justify-center";

    function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
    }

    function SenData(){
        let data = {};
        data.units = units;
        data.actions = actions;
        data.resources = resources;
        data.cardbacks = cardbacks;
        data.decks = myDecks;
        data.activeDeck = activeDeck;
        sendMessage("MCManager", "WebGLData", JSON.stringify(data))
        setDataSent(true);
        if(matchreq) {
            console.log("Match request entry: " + matchreq + " attempting to join match..");
            sendMessage("MCManager", "MatchRequest", matchreq);
        }
    }

    const handleMatchStatus = useCallback((status, matchid) => {
        if(status) compareMatch();
        setIsInMatch(status);
        setMatchID(matchid)
    }, [setIsInMatch, setMatchID]);

    useEffect(() => {
        window.addEventListener("popState",e => {
            e.preventDefault()
        });
        addEventListener("MatchStatus", handleMatchStatus);
        return () => {
            window.removeEventListener("popState",e => {
                e.preventDefault()
            });
            removeEventListener("MatchStatus", handleMatchStatus);
        };
    }, [addEventListener, removeEventListener, handleMatchStatus]);

    useEffect(() => {
        if(isLoaded){
            sendtoken();
        }
    }, [isLoaded]);

    async function sendtoken(){
        if(!id) return;
        await timeout(5000).then(() => {
            sendMessage("MCManager", "SetToken", id);
            SenData();
        });
    }

    useEffect(
        function () {
            // A function which will update the device pixel ratio of the Unity
            // Application to match the device pixel ratio of the browser.
            const updateDevicePixelRatio = function () {
                setDevicePixelRatio(window.devicePixelRatio);
            };
            // A media matcher which watches for changes in the device pixel ratio.
            const mediaMatcher = window.matchMedia(
                `screen and (resolution: ${devicePixelRatio}dppx)`
            );
            // Adding an event listener to the media matcher which will update the
            // device pixel ratio of the Unity Application when the device pixel
            // ratio changes.
            mediaMatcher.addEventListener("change", updateDevicePixelRatio);
            return function () {
                // Removing the event listener when the component unmounts.
                mediaMatcher.removeEventListener("change", updateDevicePixelRatio);
            };
        },
        [devicePixelRatio]
    );

    if((playConfirm || matchreq) && session) {
        return (
            <div className={"absolute h-screen w-screen top-0 left-0 bg-black"}>
                <Unity ref={canvasRef} devicePixelRatio={devicePixelRatio} style={{ width: "100%", height: "100%" }} unityProvider={unityProvider} tabIndex={1} />
                {(!isLoaded || !DataSent) && (<div className={"absolute top-0 left-0 flex flex-row h-full w-full justify-center items-center  bg-gradient-to-br from-[#1c252c] to-[#0a0f13]"}>
                    <div className="flex flex-row items-center justify-center" style={{ width: '60%', height: 50}}>
                        <Box sx={{width: '90%', height: "100%"}}>
                            <progress className="progress progress-accent w-56" value={loadingPercentage} max="100"></progress>
                        </Box>{loadingPercentage}%
                    </div>
                </div>)}
            </div>
        );
    } else return (
        <div className={"flex flex-col w-full h-full gap-10 justify-center items-center"}>
            <div className={"flex flex-col w-1/3 h-1/5 bg-black bg-opacity-70 rounded-2xl"}>
                <div className={"flex flex-row w-full h-1/2 justify-center items-center text-center p-4"}>
                    By clicking agree you agree that you are downloading and starting the WebGL game client.
                </div>
                <div className={"flex flex-row w-full h-1/2 justify-center items-center"}>
                    <Link to={`/game`}>
                        <Button onClick={() => {
                            setPlayConfirm(true);
                        }}>Agree</Button>
                    </Link>
                </div>
            </div>
            <div className={"flex flex-col w-2/3 h-3/5 bg-black bg-opacity-70 rounded-2xl"}>
                <div className={"flex flex-col w-full h-1/5 justify-center items-center text-center p-4"}>
                    <div className={"flex flex-row w-full h-1/2 justify-center items-center text-end p-4"}>
                        Tips
                    </div>
                    <div className={"flex flex-row w-full h-1/2 justify-center items-center text-center p-4"}>
                        To prevent disconnecting when tab is inactive in chromium based browsers you can do this
                    </div>
                </div>
                <div className={"flex flex-row w-full h-4/5 justify-center items-center p-4"}>
                    <img src={"./chrome.png"} alt={"chrome"} className={"h-full w-auto"}/>
                </div>

                </div>
            </div>
            );
            }