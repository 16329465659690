import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import {useAppContext} from "../contexts/appContext";
import {useDbContext} from "../contexts/dbContext";
import {dbstyle} from "./dbstylings";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import toast from "react-hot-toast";

export function SelectedDeck(props){
    const {activeDeck} = props;
    return (
        <>
            {activeDeck.maindeck.map((el) =>
                <Cardentry card={el} type={el.type}/>
            )}
        </>
    )
}

export function SelectedRDeck(props){
    const {activeDeck} = props;
    return (
        <>
            {activeDeck.resourcesdeck.map((el)=>
                <Cardentry card={el} type={"resources"}/>
            )}
        </>
    )
}

export function SelectSavedDeck(props){
    const {activeDeck} = props;
    return (
        <>
            {activeDeck.offdeck.length > 0 && activeDeck.offdeck.map((el) =>
                <Cardentry card={el} type={el.type}/>
            )}
        </>
    )
}

function Cardentry(props) {
    const {card, type} = props;
    return (
        <div className={"flex flex-row h-14 justify-between items-center py-1 w-full"}>
            <div style={{position: "absolute"}}
                 className={"w-full h-full bg-[#262012] opacity-70 border-slate-400 border-b"}></div>
            <div className={"flex flex-row h-full w-full items-center overflow-hidden"}>
                <div className={"flex h-full w-1/5 flex-row justify-center items-center"}>{card.amount}</div>
                <div className={"flex h-full w-3/5 flex-row justify-center items-center ml-2"}>{card.Name}</div>
                <div className={"flex flex-row w-10 h-10 " + dbstyle.button} onClick={() => {
                    // type === "resources" ? ROTEUI.removeFromResourcesDeck(card.Id, card.Name) : ROTEUI.removeFromDeck(card.Id, type, card.Name)
                }}>
                    -
                </div>
            </div>

        </div>
    )
}

export function Card(props) {
    const {card, cardsOnly} = props;
    const {session, cardsdate} = useAppContext();
    const {
        AddCard,
        SaveDeck,
        RemoveCard,
        activeDeck,
        cardSize,
        IsInDeck,
        maxmain,
        maxmana,
        totalAmountMain,
        totalAmountResources,
        RemoveBench,
        AddBench,
        IsInBench,
        SetResourceAmount
    } = useDbContext();
    const [contextMenu, setContextMenu] = useState(null);
    let amount = IsInDeck(card.Id, card.type === "resources");

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu(
            contextMenu === null
                ? {
                    mouseX: event.clientX + 2,
                    mouseY: event.clientY - 6,
                }
                : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
                  // Other native context menus might behave different.
                  // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
                null,
        );
    };

    const handleAdd = () => {
        if (AddCard(card) && activeDeck.id !== "") {
            SaveDeck();
            setContextMenu(null);
        }
    };

    const handleAddBench = () => {
        if (AddBench(card) && activeDeck.id !== "") {
            SaveDeck();
            setContextMenu(null);
        }
    };

    const handleRemove = () => {
        if (RemoveCard(card) && activeDeck.id !== "") {
            SaveDeck();
            setContextMenu(null);
        }
    };

    const handleRemoveBench = () => {
        if (RemoveBench(card) && activeDeck.id !== "") {
            SaveDeck();
            setContextMenu(null);
        }
    };

    const handleClose = () => {
        setContextMenu(null);
    };

    const size2 = () => {
        if (window.innerWidth <= 500) return 0.25;
        else return cardSize;
    }
    let max = card.type === "resources" ? 12 : maxmain;
    let indeck = card.type === "resources" ? totalAmountResources() : totalAmountMain();

    if (cardsOnly) {
        return (
            <div style={{cursor: 'context-menu', height: (1040 * size2()), width: 744 * size2()}}
                 className={"flex flex-col relative select-none overflow-hidden rounded-xl items-center justify-start border-2 border-[#716039]"}>
                <img style={{height: (1040 * size2()), width: 744 * size2()}} alt={card.Id}
                     src={"cards/" + card.Id + ".png"} key={cardsdate}/>
            </div>
        )
    } else {
        if (card.type === "resources") {
            return (
                <div style={{cursor: 'context-menu', height: (1040 * size2()) + 35, width: 744 * size2()}}
                     className={"flex flex-col relative select-none overflow-hidden rounded-xl items-center justify-start " + (amount > 0 ? " border-4 border-[#716039] " : " border-2 border-[#716039] ")}>
                    <img style={{height: (1040 * size2()), width: 744 * size2()}} alt={card.Id}
                         src={"cards/" + card.Id + ".png"} key={cardsdate}/>
                    <div
                        className={"flex relative flex-row h-[30px] w-full bg-black bg-opacity-40 border-t border-[#716039] justify-between items-center px-2"}>
                        <Tooltip title={"Remove 1 from mana deck"} placement={"top"}>
                            <div
                                className={"flex flex-row h-8 w-10 bg-red-500 bg-opacity-20 justify-center items-center hover:bg-opacity-40"}
                                onClick={(e) => {
                                    if (!cardsOnly && session) {
                                        if (RemoveCard(card) && activeDeck.id !== "") SaveDeck();
                                    }
                                }}
                            >-
                            </div>
                        </Tooltip>
                        {amount}
                        <Tooltip title={"Add 1 to mana deck"} placement={"top"}>
                            <div
                                className={"flex flex-row h-8 w-10 bg-green-500 bg-opacity-20 justify-center items-center hover:bg-opacity-40"}
                                size={"small"}
                                onClick={(e) => {
                                    if (!cardsOnly && session) {
                                        if (indeck < 12) {
                                            if (AddCard(card) && activeDeck.id !== "") SaveDeck();
                                        }
                                    }
                                }}
                            >+
                            </div>
                        </Tooltip>
                    </div>
                </div>
            )
        } else
            return (
                <div onClick={(e) => {
                    if (!cardsOnly && session && e.detail === 2) {
                        if (amount > 0) {
                            if (RemoveCard(card) && activeDeck.id !== "") SaveDeck();
                        } else {
                            if (indeck < 50) {
                                if (AddCard(card) && activeDeck.id !== "") SaveDeck();
                            } else {
                                if (!IsInBench(card.Id)) {
                                    if (AddBench(card) && activeDeck.id !== "") {
                                        SaveDeck();
                                        toast.error("Main deck already contains 50 cards, card placed into your bench.")
                                    }
                                }
                            }
                        }
                    }
                }} onContextMenu={handleContextMenu}
                     style={{
                         cursor: 'context-menu',
                         height: 1040 * size2(),
                         width: 744 * size2(),
                     }}
                     onMouseLeave={() => {
                         handleClose();
                     }}
                     className={"flex relative mt-1 overflow-hidden select-none  " && (amount > 0 ? dbstyle.cards.selected : dbstyle.cards.notselected)}>
                    <div
                        className={"relative flex flex-col w-full h-full rounded-lg justify-between items-end text-center text-3xl"}>
                        {!cardsOnly && session &&
                            <div className={"absolute flex-row h-auto w-full justify-end items-end"}>
                                <div
                                    className={"flex flex-row h-auto w-auto justify-end items-end text-green-500 text-lg"}>
                                    {IsInBench(card.Id) &&
                                        <p className={"select-none text-xs font-bold bg-yellow-500 opacity-60 text-black flex flex-row justify-center items-center rounded-full w-5 h-5"}>
                                            B
                                        </p>}
                                    {amount > 0 &&
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                             className="w-6 h-6">
                                            <path fillRule="evenodd"
                                                  d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z"
                                                  clipRule="evenodd"/>
                                        </svg>}
                                </div>
                            </div>}
                        <Menu open={contextMenu !== null} onClose={handleClose} anchorReference="anchorPosition"
                              anchorPosition={
                                  contextMenu !== null
                                      ? {top: contextMenu.mouseY, left: contextMenu.mouseX}
                                      : undefined
                              }
                        >
                            {activeDeck && (indeck < 50) && (amount < max) &&
                                <MenuItem onClick={handleAdd}>Add</MenuItem>}
                            {activeDeck && !IsInBench(card.Id) &&
                                <MenuItem onClick={handleAddBench}>Add Bench</MenuItem>}
                            {activeDeck && amount > 0 && <MenuItem onClick={handleRemove}>Remove</MenuItem>}
                            {activeDeck && IsInBench(card.Id) &&
                                <MenuItem onClick={handleRemoveBench}>Remove Bench</MenuItem>}
                        </Menu>
                        <img loading={"lazy"} alt={card.Id} src={"cards/" + card.Id + ".png"} key={cardsdate}
                             className={"h-full w-full"}/>
                    </div>
                </div>
            )
    }
}